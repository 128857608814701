import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/SEO'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>Shipwreck!</h1>
    <h2>Sorry, this page doesn't exist.</h2>
    <p>
      Back to <Link to="/">Home</Link>
    </p>
  </>
)

export default NotFoundPage
